// import "../scss/swipers.scss";
import "swiper/css";
import "swiper/css/autoplay";

if (document.querySelector(".main-slider")) {
  (async () => {
    const { MainSlider } = await import("./sliders/MainSlider");
    new MainSlider(".main-slider");
  })();
}
if (document.querySelector(".blog-slider")) {
  (async () => {
    const { BlogSlider } = await import("./sliders/BlogSlider");
    new BlogSlider(".blog-slider");
  })();
}
if (document.querySelector(".reviews-slider")) {
  (async () => {
    const { ReviewsSlider } = await import("./sliders/ReviewsSlider");
    new ReviewsSlider(".reviews-slider");
  })();
}
if (document.querySelector(".slider-gallery-block")) {
  (async () => {
    const { BlockSliderGallery } = await import("./sliders/BlockSliderGallery");
    document
      .querySelectorAll<HTMLElement>(".slider-gallery-block")
      .forEach((gallery) => {
        new BlockSliderGallery(gallery);
      });
  })();
}
if (document.querySelector(".employees-slider")) {
  (async () => {
    const { EmployeesSlider } = await import("./sliders/EmployeesSlider");
    new EmployeesSlider(".employees-slider");
  })();
}
